<template>
  <DynamicForm
    :initial-values="{
      name: tag?.name,
      description: tag?.description,
      color: tag?.color
    }"
    :loading="loading"
    :schema="{
      fields: [
        {
          as: 'InputText',
          label: 'Name',
          name: 'name',
          rules: yup.string().required().label('Name')
        },
        {
          as: 'ColorPicker',
          inline: true,
          label: 'Color',
          name: 'color',
          rules: yup.string().required().label('color')
        },
        {
          as: 'Textarea',
          autoResize: true,
          label: 'Description',
          name: 'description',
          rules: yup.string().nullable().label('Description')
        },
      ]
    }"
    ref="form"
    @submit="handleSubmit"
  />
</template>

<script lang="ts" setup>
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import type DynamicForm from '~/components/DynamicForm.vue'

const dialogRef = inject<ComputedRef<DynamicDialogInstance>>('dialogRef')
const form = ref<typeof DynamicForm>()
const loading = ref<boolean>(false)
const tag = ref<Tag>()

onBeforeMount(() => {
  if (dialogRef) tag.value = dialogRef.value.data?.tag
})

const handleSubmit = async (values: TagCreateInput | TagUpdateInput) => {
  loading.value = false

  const url = tag.value ? `/tags/${tag.value.id}/` : '/tags/'

  const { $api } = useNuxtApp()
  await $api<Tag>(url, {
    method: tag.value ? 'PUT' : 'POST',
    body: JSON.stringify(values)
  })
    .then(() => dialogRef?.value.close())
    .catch((error: TagCreateError|TagUpdateError) => form.value?.setErrors(error.data))
    .finally(() => loading.value = false)
}
</script>
